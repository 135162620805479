import * as React from "react";
import {
   createStyles,
   Title,
 } from '@mantine/core';


const useStyles = createStyles((theme) => ({
   inner: {
     display: 'flex',
     justifyContent: 'space-between',
     paddingTop: theme.spacing.xl * 4,
     paddingBottom: theme.spacing.xl * 4,
   },
 
   content: {
     maxWidth: 480,
     marginRight: theme.spacing.xl * 3,
 
     [theme.fn.smallerThan('md')]: {
       maxWidth: '100%',
       marginRight: 0,
     },
   },
 
   title: {
      fontFamily: "Open Sans,Helvetica Neue,Arial,Helvetica,Verdana,sans-serif",
      fontSize: 40,
      paddingTop: "2em",
      fontWeight: "500",
     [theme.fn.smallerThan('xs')]: {
       fontSize: 28,
     },
   },
 
 }));

export const Heading = ({as, children, color, align, style}) => {

  const { classes } = useStyles();
  return (
   <Title component={as} className={classes.title} sx={{color: color}} align={align} style={style}>
      {children}
   </Title>
  );
};

export default Heading;