import * as React from "react";
import Layout from "../components/layout";
import { SimpleGrid } from '@mantine/core';
import { Heading } from "../components/text/heading";
import { Lead } from "../components/text/lead";
import { CardWithImages } from '../components/display/card'

import hardwoodImg from "../images/jobs/hardwood.jpg";
import vinylPlankLivingRoom from "../images/jobs/vinyl-plank-living-room.jpg";
import laminateImg from "../images/jobs/laminate.jpg";
import laminateStairs from "../images/jobs/laminate-stairs.jpg";
import laminateAccentWall from "../images/jobs/laminate-accent-wall.jpg";
import laminateOffice from "../images/jobs/laminate-office.jpg";
import vinylPlankEntry from "../images/jobs/vinyl-plank-entry.jpg";
import laminateDeck from "../images/jobs/laminate-deck.jpg";

const jobs = [
  {
    id: 1,
    title: "Hardwood",
    image: hardwoodImg,
  },
  {
    id: 2,
    title: "Vinyl Plank Flooring",
    image: vinylPlankLivingRoom,
  },
  {
    id: 4,
    title: "Laminate Stairs",
    image: laminateStairs,
  },
  {
    id: 5,
    title: "Laminate accent wall",
    image: laminateAccentWall,
  },
  {
    id: 7,
    title: "Vinyl plank entryway",
    image: vinylPlankEntry,
  },
  {
    id: 8,
    title: "Laminate",
    image: laminateDeck,
  },
  {
    id: 6,
    title: "Laminate flooring",
    image: laminateOffice,
  },
  {
    id: 3,
    title: "Laminate",
    image: laminateImg,
  },
];

function JobItem({ title, image }) {
  return (
    <CardWithImages image={image} title={title}/>
  );
}

const Jobs = () => {
  const JobItems = jobs.map((job) => (
    <JobItem key={job.id} title={job.title} image={job.image} />
  ));
  return (
    <Layout>
      <section>
        <Heading as="h1" variant="h1" align="center">
          Jobs
        </Heading>
        <Lead element="p" align="center">
          Check out some of the recent jobs we've completed.
        </Lead>
        <SimpleGrid breakpoints={[
        { minWidth: 'sm', cols: 2 },
        { minWidth: 'md', cols: 3 },
        { minWidth: 1200, cols: 4 },
      ]} sx={{padding: '2em 4em'}}>{JobItems}</SimpleGrid>
      </section>
    </Layout>
  );
};

export default Jobs;
