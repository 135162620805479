import * as React from "react";
import { Card, Group, Text, Image, SimpleGrid } from '@mantine/core';

import comingSoonImg from '../../images/jobs/coming-soon.png'
const images = [ comingSoonImg, comingSoonImg, comingSoonImg ]


export function CardWithImages({title, image}) {
  return (
    <Card withBorder shadow="sm" radius="md">
      <Card.Section withBorder inheritPadding py="xs">
        <Group position="apart">
          <Text weight={500}>{title}</Text>
        </Group>
      </Card.Section>

      <Card.Section mt="0">
        <Image src={image} />
      </Card.Section>

      <Card.Section inheritPadding mt="sm" pb="md">
        <SimpleGrid cols={3}>
          {images.map((image, index) => (
            <Image src={image} key={index} radius="sm" />
          ))}
        </SimpleGrid>
      </Card.Section>
    </Card>
  );
}